<template>
  <b-overlay
    class="h-100"
    id="overlay-background"
    :show="mostrarOverlay"
    :variant="'dark'"
    :opacity="1"
    :blur="'2px'"
    rounded="sm"
  >
    <template #overlay>
      <div class="card position-fixed card-codigo">
        <div class="card-body d-block w-100">
          <div
            class="d-flex flex-column justify-content-around align-items-center h-100"
          >
            <b-icon-shield-lock-fill
              class="mt-3"
              scale="6"
            ></b-icon-shield-lock-fill>
            <b-form class="mt-6" @submit.stop.prevent="onSubmit">
              <b-form-group
                id="input-group-2"
                label="Senha:"
                label-for="input-2"
                description="Esta senha de segurança é criada quando a campanha é cadastrada."
              >
                <b-form-input
                  id="input-2"
                  v-model="senha"
                  type="password"
                  :state="senhaCorreta"
                  placeholder="Digite o código de acesso"
                  required
                ></b-form-input>
                <b-form-invalid-feedback :state="senhaCorreta">
                  Verifique o código informado.
                </b-form-invalid-feedback>
              </b-form-group>
              <div class="d-flex justify-content-end w-100">
                <b-button
                  type="submit"
                  variant="primary"
                  class="d-flex align-items-center"
                >
                  <b-spinner
                    v-if="verificandoSenha"
                    small
                    class="mr-1"
                  ></b-spinner>
                  Entrar</b-button
                >
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </template>

    <div
      class="d-flex justify-content-center align-items-center h-100 p-1"
      v-if="carregando"
    >
      <v-progress-circular
        indeterminate
        size="32"
        color="#462181"
        width="2"
        v-if="mensagem == ''"
        ><v-progress-circular
          indeterminate
          size="24"
          color="#ff7f00"
          width="2"
          rotate="180"
        ></v-progress-circular
      ></v-progress-circular>
      <span style="font-size: 2rem">
        {{ mensagem }}
      </span>
    </div>

    <!-- <div
      v-else
      class="d-flex flex-wrap justify-content-between align-content-between p-0 m-1"
    > -->
    <div v-else class="row pl-3 w-100">
      <div v-for="(i, index) in lista" :key="index" class="col p-1 m-0">
        <div
          class="card card-amostra"
          :class="classeCard(i.atendimento_horario_minuto)"
        >
          <div class="card-body p-0 h-100 w-100">
            <div class="cabecalho py-2 px-1 w-100">
              <div
                class="d-flex justify-content-between align-items-center px-4 h-100 w-100"
              >
                <b-icon-alarm scale="2.7" />
                <div>
                  <div class="d-flex">
                    <h4>
                      <b
                        >{{ i.atendimento_data_en || "-" }}
                        {{ i.atendimento_horario_hora }}
                      </b>
                    </h4>
                  </div>
                  <h5
                    style="
                      display: flex;
                      flex-wrap: wrap;
                      width: 180px;
                      font-size: 0.95rem;
                    "
                  >
                    {{ i.unidade_nome ? i.unidade_nome.trim() : "-" || "-" }}
                  </h5>
                </div>
                <div>
                  <h5>{{ textoCabecalho(i.atendimento_horario_minuto) }}</h5>
                  <div class="d-flex align-items-baseline">
                    <b-icon-hourglass-split scale="1.6" class="mr-2" />
                    <h3>
                      {{
                        minAtendimentoFormatado(
                          i.atendimento_horario_minuto
                        ).join("")
                      }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="corpo d-flex flex-column justify-content-center w-100">
              <div
                class="d-flex justify-content-between align-items-center amostra-urgente"
                :class="i.exame_urgente == 'S' ? 'amostra-urgente-cor' : ''"
              >
                <div>
                  <div class="d-flex align-items-baseline">
                    <b-icon-person scale="2" class="mr-3" />
                    <h6 class="nome">{{ i.cliente_nome }}</h6>
                  </div>
                  <div class="d-flex align-items-baseline">
                    <b-icon-card-checklist scale="1.8" class="mr-3" />
                    <h6 class="nome">{{ i.exame_rotulo }}</h6>
                  </div>
                  <div class="d-flex align-items-baseline">
                    <b-icon-journal-text scale="1.6" class="mr-3" />
                    <h6 class="nome">
                      {{ i.atendimento_numero_serie }} / {{ i.amostra_id }}
                    </h6>
                  </div>
                </div>
                <div
                  v-if="i.exame_urgente == 'S'"
                  class="d-flex flex-column justify-content-center align-items-center"
                >
                  <!-- <i
                    class="flaticon2-warning icon-5x m-0"
                    style="color: #ed6f00"
                  ></i> -->
                  <b-icon-exclamation-triangle-fill
                    scale="4"
                    class="icon-urgente"
                  />
                  <!-- <div>
                    <i class="huge warning sign yellow icon"></i>
                  </div> -->
                  <p><b class="m-0 label-urgente">URGENTE</b></p>
                </div>
                <!-- <div v-else style="width: 65px; height: 98px"></div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import ApiService from "@/core/services/api.service";
import API_LINKS from "../automacao/api.links";
import { mapGetters } from "vuex";
import moment from "moment";
import "moment/locale/pt-br";

export default {
  name: "PainelAmostra",
  data() {
    return {
      nomesFaker: [],
      mostrarOverlay: false,
      modalErro: false,
      validation: true,
      senha: null,
      verificandoSenha: false,
      senhaCorreta: null,
      lista: [],
      configuracoes: {},
      erros: [],
      uidAtual: "",
      uidAnterior: "",
      semMudanca: 0,
      monitor: null,
      monitorTimeout: 0,
      mensagem: ""
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
    carregando() {
      return !this.lista || this.lista.length == 0;
    }
  },
  watch: {
    lista(valorNovo) {
      if (valorNovo && valorNovo.length == 0) {
        this.mensagem = "A lista de exames está vazia";
      } else {
        this.mensagem = "";
      }
    }
  },
  methods: {
    monitorStatusRequisicao() {
      this.monitor = setTimeout(() => {
        this.semMudanca += this.uidAnterior == this.uidAtual;
      }, 5000);
    },
    monitorarTimeout() {
      this.monitorTimeout = setInterval(() => {
        if (this.uidAtual != "" && this.uidAnterior == this.uidAtual) {
          this.semMudanca += 1;
        } else {
          this.semMudanca = 0;
        }

        if (this.semMudanca > 5) {
          this.semMudanca = 0;
          //Informar que a requisição ta a muito tempo sem responder corretamente
          this.$bvToast.hide("toast1");
          this.$bvToast.toast(
            `O sistema está há bastante tempo sem responder às solicitações, verifique o agente ou entre em contato conosco.`,
            {
              id: "toast1",
              title: "Problema de conexão",
              autoHideDelay: 10000,
              appendToast: false,
              variant: "warning"
            }
          );
        }
      }, 5000);
    },
    async atualizarAmostras(loader = false) {
      if (this.atualizandoAmostras) {
        return;
      }
      this.atualizandoAmostras = true;
      // this.carregando = true && loader;
      try {
        if (this.uidAtual == "") {
          let responseSolicitacao = await this.solicitarUid();
          let data = responseSolicitacao.data;
          if (data.requisicao) {
            let uid = data.requisicao.uid;
            this.uidAtual = uid;
            this.configuracoes = data.configuracoes;
            this.uidAnterior = this.uidAtual;
          }

          // Verifica se a senha informada, está correta.
          if (
            responseSolicitacao.status == 404 &&
            responseSolicitacao.data.erro ==
              "Tela de Amostragem solicitada não encontrada com as credenciais informadas!"
          ) {
            this.senhaCorreta = false;
            this.mostrarTelaSenha();
            this.pararAtualizacao();
          }
        }

        let resp = await this.resposta(this.uidAtual);
        if (resp.data.requisicao.requisicao.status !== "PENDENTE") {
          this.lista = resp.data.requisicao.requisicao.resultado;
          this.lista = Array.isArray(this.lista) ? this.lista : [];
          this.uidAtual = "";
          this.addAtributo();
        }
        if (!resp.data.requisicao.requisicao.sucesso_requisicao) {
          this.$bvToast.toast(
            `Não foi possível realizar a consulta, verifique problemas no agente.`,
            {
              id: "toast1",
              title: "Problema de consulta",
              autoHideDelay: 10000,
              appendToast: false,
              variant: "warning"
            }
          );
        }
      } catch (error) {
        this.pararAtualizacao();
      }

      this.atualizandoAmostras = false;
    },
    async solicitarUid() {
      let responseSolicitacao;
      try {
        if (this.isAuthenticated) {
          responseSolicitacao = await ApiService.post(
            process.env.VUE_APP_COMUNICANTE_API +
              "/campanha/painel-amostra/" +
              this.modelo_campanha_id
          );
        } else {
          responseSolicitacao = await ApiService.post(
            process.env.VUE_APP_COMUNICANTE_API +
              "/campanha/painel-amostra/" +
              this.modelo_campanha_id,
            {
              senha: this.senha
            }
          );
        }
      } catch (error) {
        return responseSolicitacao;
      }

      return responseSolicitacao;
    },
    async resposta(uid) {
      let responseResposta;

      if (this.isAuthenticated) {
        responseResposta = await ApiService.post(
          process.env.VUE_APP_COMUNICANTE_API +
            "/campanha/resposta-painel-amostra/" +
            this.modelo_campanha_id,
          {
            uid: uid
          }
        );
      } else {
        responseResposta = await ApiService.post(
          process.env.VUE_APP_COMUNICANTE_API +
            "/campanha/resposta-painel-amostra/" +
            this.modelo_campanha_id,
          {
            senha: this.senha,
            uid: uid
          }
        );

        return responseResposta;
      }

      return responseResposta;
    },
    ordenar() {
      this.lista = this.lista.sort((a, b) => {
        if (a.atendimento_horario_minuto > b.atendimento_horario_minuto) {
          return 1;
        } else if (
          a.atendimento_horario_minuto < b.atendimento_horario_minuto
        ) {
          return -1;
        } else {
          return 0;
        }
      });
    },
    addAtributo() {
      if (this.lista) {
        this.lista.forEach((item) => {
          let milis = new Date(
            item.atendimento_data_en.split("/").reverse().join("-") +
              " " +
              item.atendimento_horario_hora
          ).getTime();

          item._atendimento = new Date(
            new Date(milis + item.atendimento_horario_minuto * 60 * 1000)
          );
        });
      }
    },
    exibirTelaSenha() {
      this.pararAtualizacao();
      this.mostrarOverlay = true;
    },
    obterDataFormatada(data, formatEntrada, formatSaida) {
      // Exemplo Entrada: "YYYY-MM-DD hh:mm"
      // Exemplo Saida: "DD[/]MM[/]YY HH:mm"
      return data
        ? moment(data, formatEntrada).locale("pt-br").format(formatSaida)
        : "-";
    },
    minAtendimentoFormatado(valor) {
      valor = Math.abs(valor);
      if (valor < 59) return [valor, "min"];
      else if (valor < 1440) {
        return [Math.floor(valor / 60), "h", valor % 60, "m"];
      } else {
        return [
          Math.floor(valor / 1440),
          `dia${Math.floor(valor / 1440) >= 2 ? "s" : ""}`,
          Math.floor((valor % 1440) / 60),
          "h"
        ];
      }
    },
    statusAmostra(valor) {
      try {
        let tempo = this.configuracoes.find(
          (item) => item.sistema_configuracao_id == 35
        );
        // Atrasado: 1; Proximo: 0, Novo: -1
        if (valor < 0) {
          return 1;
        } else if (valor > 0 && valor <= (tempo.sic_valor ?? 45)) {
          return 0;
        } else {
          return -1;
        }
        // return valor < 0
        //   ? 1
        //   : valor > 0 && valor <= (tempo.sic_valor ?? 45)
        //   ? 0
        //   : -1;
      } catch (error) {
        this.erro = 1;
      }
    },
    classeCard(valor) {
      let status = this.statusAmostra(valor);
      return status == -1
        ? "card-atrasado"
        : status == 0
        ? "card-alerta"
        : "card-ideal";
    },
    textoCabecalho(valor) {
      let status = this.statusAmostra(valor);
      return status == -1 ? "Atrasado há" : "Restam";
    },
    mostrarTelaSenha() {
      this.mostrarOverlay = true;
    },
    async onSubmit(event) {
      event.preventDefault();
      this.verificandoSenha = true;
      this.atualizarAmostras(true)
        .then(() => {
          this.mostrarOverlay = false;
          this.verificandoSenha = false;
          localStorage.codigo = this.senha;
        })
        .catch((erro) => {
          this.validation = true;
          this.verificandoSenha = false;
        });
    },
    iniciaAtualizacao() {
      const self = this;
      this.interval = setInterval(() => {
        self.atualizarAmostras();
      }, 40000); // 40 segundos
    },
    pararAtualizacao() {
      clearInterval(this.interval);
    }
  },
  async mounted() {
    this.modelo_campanha_id = this.$route.params.modelo_campanha_id;
    try {
      if (this.isAuthenticated) {
        this.sistema_licenciada_id = localStorage.sistema_licenciada_id; //this.$route.params.campanha;
        //localStorage.removeItem("sistema_licenciada_id");

        let responseCampanha = await ApiService.get(
          "/administrativo/campanha",
          this.modelo_campanha_id
        );

        this.campanha = responseCampanha.data.campanha;

        // Verifica se o usuário tem permissões para acessar as amostras.
        let response = await ApiService.post(
          process.env.VUE_APP_COMUNICANTE_API +
            "/campanha/painel-amostra/" +
            this.modelo_campanha_id,
          false
        );

        let data = response.data;
        if (data.sucesso) {
          // Caso usuario tenha permissão, carrega as amostras.

          // Salvando configurações do sistema e procurando a senha de acesso
          let resp = await ApiService.get(
            API_LINKS.sistemaLicenciadaConfiguracoes +
              "/ativos/" +
              this.campanha.sistema_licenciada_id ?? this.sistema_licenciada_id,
            false
          );
          this.configuracoes = resp.data.configuracoes;
          this.senha = this.configuracoes.find(
            (item) => item.sistema_configuracao_id == 34
          ).sic_valor;

          if (!this.senha) throw "SemSenhaException";
          else {
            localStorage.codigo = this.senha;
            this.atualizarAmostras(true);
            this.monitorarTimeout();
            this.iniciaAtualizacao();
          }
        }
      }

      if (this.sistema_licenciada_id == undefined) throw "SemSenhaException";
    } catch (SemSenhaException) {
      // Verificar se contém a senha no Local Storage
      this.senha = localStorage.codigo;
      if (!this.senha) this.exibirTelaSenha();
      else {
        try {
          this.atualizarAmostras(true);
          this.iniciaAtualizacao();
          this.monitorarTimeout();
        } catch (Exception) {
          this.erros.push({
            timestamp: new Date(),
            erro: Exception
          });
          localStorage.removeItem("codigo");
          this.exibirTelaSenha();
        }
      }
    }
  },
  beforeDestroy() {
    this.pararAtualizacao();
  }
};
</script>

<style lang="scss">
// @media (max-width: 1621px) {
//   .card-amostra {
//     min-width: 350px;
//     height: 205px;
//   }
// }

// @media (min-width: 1622px) {
//   .card-amostra {
//     min-width: 350px;
//     height: 205px;
//   }
// }

.nome {
  font-size: 0.95rem;
}

.card-amostra {
  min-width: 340px;
  // max-width: 360px;
  height: 170px;
}

/* Tempo Ideal */
.card-ideal {
  border: solid 1px #00b45e;
}

.card-ideal > .card-body > .cabecalho {
  background-color: #00b45e;
  color: white;
  font-weight: 800;
}

.card-ideal > .card-body > .corpo {
  padding: 10px 5px;
}

/* Proximo de atrasar */
.card-alerta {
  border: solid 1px #f3bb2b;
}

.card-alerta > .card-body > .cabecalho {
  background-color: #f3bb2b;
  color: #000000;
  font-weight: 800;
}

.card-alerta > .card-body > .corpo {
  padding: 10px 5px;
}

/* Com atraso */
.card-atrasado {
  border: solid 1px #aa0404;
}

.card-atrasado > .card-body > .cabecalho {
  background-color: #b80606;
  color: white;
  font-weight: 800;
}

.card-atrasado > .card-body > .corpo {
  padding: 10px 5px;
}

.amostra-urgente {
  padding: 5px 8px;
  border-radius: 5px;
}

.amostra-urgente-cor {
  background-color: #f2da00;
}

.icon-urgente {
  position: relative;
  top: 0px;
  right: 30px;
  color: #ed6f00;
  z-index: 99;
}

.label-urgente {
  position: absolute;
  bottom: 0.8rem; //6px;
  left: 22.2rem; //288px;
  color: #ed6f00;
  z-index: 0;
}

@media (max-width: 1621px) {
  .label-urgente {
    position: absolute;
    bottom: 0.8rem;
    left: 23.8rem;
    color: #ed6f00;
    z-index: 99;
  }
}

.card-codigo {
  /* position: relative; */
  top: -250px;
  left: -150px;
  width: 300px;
  height: 300px;
}

/* Icone de Warning */

.warn,
.warn::before,
.warn::after {
  position: relative;
  padding: 0;
  margin: 0;
}

.warn {
  font-size: 36px;
  color: transparent;
}

.warn.warning {
  display: inline-block;

  top: 0.225em;

  width: 1.15em;
  height: 1.15em;

  overflow: hidden;
  border: none;
  background-color: transparent;
  border-radius: 0.625em;
}

.warn.warning::before {
  content: "";
  display: block;
  top: -0.08em;
  left: 0em;
  position: absolute;
  border: transparent 0.6em solid;
  border-bottom-color: #fd3;
  border-bottom-width: 1em;
  border-top-width: 0;
  box-shadow: #999 0 1px 1px;
}

.warn.warning::after {
  display: block;
  position: absolute;
  top: 0.3em;
  left: 0;
  width: 100%;
  padding: 0 1px;
  text-align: center;
  font-family: "Garamond";
  content: "!";
  font-size: 0.65em;
  font-weight: bold;
  color: #333;
}
</style>
